import React from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import {DEFAULT_THEME, ThemeProvider} from "@zendeskgarden/react-theming";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LicenseActivationPage from "./containers/LicenseActivation";
import LicenseOverviewPage from "./containers/LicenseOverview";
import LicenseAssignmentPage from "./containers/LicenseAssignment";
import {ToastProvider} from "@zendeskgarden/react-notifications";

const topProps = {
  style: { top: DEFAULT_THEME.space.base * 3 }
};
const bottomProps = {
  style: { bottom: DEFAULT_THEME.space.base * 3 }
};
const placementProps = {
  'top-start': topProps,
  top: topProps,
  'top-end': topProps,
  'bottom-start': bottomProps,
  bottom: bottomProps,
  'bottom-end': bottomProps
};


const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <ThemeProvider>
        <BrowserRouter>
          <PageLayout>
            <ToastProvider placementProps={placementProps} zIndex={1000}>
            <Routes>
              <Route path="/activation" element={<LicenseActivationPage />} />
              <Route path="/overview" element={<LicenseOverviewPage />} />
              <Route path="/assignment/:subscriptionId" element={<LicenseAssignmentPage />} />
              <Route path="/" element={<LicenseOverviewPage />} />
            </Routes>
            </ToastProvider>
          </PageLayout>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
