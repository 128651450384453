import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Grid, Row, Col } from "@zendeskgarden/react-grid";
import { Button } from "@zendeskgarden/react-buttons";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  activateSubscription,
  resolveSubscripton,
} from "../api/licenseManagement";
import {Skeleton} from "@zendeskgarden/react-loaders";

function LicenseActivationPage() {
  const [searchParams] = useSearchParams();
  const subscriptionToken = searchParams.get("token");
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (
      !loading &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      if (subscriptionData) {
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }
      const tokenRequest = {
        scopes: process.env.REACT_APP_API_SCOPES.split(' '),
      };
      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        // Call your API with the access token and return the data you need to save in state
        // the
        if (subscriptionToken === null) {
          return;
        }
        resolveSubscripton(response.accessToken, subscriptionToken).then(
          (data) => {
            setSubscriptionData(data);
            setLoading(false);
          }
        );
      }).catch(async (e) => {
         // Catch interaction_required errors and call interactive method to resolve
         if (e instanceof InteractionRequiredAuthError) {
           await instance.acquireTokenRedirect(tokenRequest);
         }
         throw e;
       });
    }
  }, [inProgress, accounts, instance, loading, subscriptionData, subscriptionToken]);

  async function activateSubscriptionFunc() {
    const tokenRequest = {
      scopes: process.env.REACT_APP_API_SCOPES.split(' '),
    };
    // Acquire an access token
    instance
      .acquireTokenSilent(tokenRequest)
      .then((response) => {
        // Call your API with the access token and return the data you need to save in state
        activateSubscription(
          response.accessToken,
          subscriptionData.id,
          subscriptionData.planId
        ).then((_) => navigate("/overview"));
      })
      .catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }
        throw e;
      });
  }

  const SubscriptionField = ({ label, value }) => (
      <Row className="py-3">
        <Col>
          <Field>
            <Label>{label}</Label>
            { value ? <Input readOnly value={value} /> : <Skeleton style={{ marginTop: '8px' }} width="100%" height="40px"/> }
          </Field>
        </Col>
      </Row>
  );

  return (
    <div className="max-w-md mx-auto flex p-6 bg-gray-100 rounded-lg shadow-xl">
      <Grid gutters="lg">
        <Row className="py-3">
          <Col>
            <h1 className="text-xl font-bold">Subscription activation</h1>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <p>
              We're fetching your subscription details. Once verified, you can
              activate it by clicking 'Activate'.
            </p>
          </Col>
        </Row>
        <SubscriptionField label="Offer ID" value={subscriptionData?.offerId} />
        <SubscriptionField label="Plan ID" value={subscriptionData?.planId} />
        <SubscriptionField
          label="Subscription Name"
          value={subscriptionData?.subscription.name}
        />
        <SubscriptionField
          label="Purchaser Email"
          value={subscriptionData?.subscription.purchaser.emailId}
        />
        <SubscriptionField
          label="Publisher ID"
          value={subscriptionData?.subscription.publisherId}
        />
        <Row className="py-5 pb-10">
          <Col>
            <Button
              isPrimary
              onClick={() => activateSubscriptionFunc()}
              disabled={!subscriptionData}
            >
              Activate subscription
            </Button>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default LicenseActivationPage;
