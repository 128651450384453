import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Dropdown, Menu, Item, Trigger } from "@zendeskgarden/react-dropdowns";
import {
  Header,
  HeaderItem,
  HeaderItemIcon,
  HeaderItemText,
} from "@zendeskgarden/react-chrome";
import { Button } from "@zendeskgarden/react-buttons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../src/assets/img/logo.svg";

export const NavigationBar = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const handleLoginRedirect = (token) => {
    const state = encodeURIComponent(`token=${token}`);

    instance
      .loginRedirect({
        ...loginRequest,
        state: state,
      })
      .catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };
  const navigateOverview = () => navigate("/overview");
  const navigateMain = () => navigate("/");

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <Header isStandalone>
        <HeaderItem hasLogo onClick={navigateMain} className="cursor-pointer">
          <HeaderItemIcon>
            <Logo style={{ transform: "scale(1.6)" }} />
          </HeaderItemIcon>
          <HeaderItemText>Zendesk Garden</HeaderItemText>
        </HeaderItem>
        <AuthenticatedTemplate>
          <HeaderItem onClick={navigateOverview}>
            <HeaderItemText>Licenses</HeaderItemText>
          </HeaderItem>
          <HeaderItem>
            <Dropdown>
              <Trigger>
                <Button>
                  {activeAccount
                    ? activeAccount.username.split("@")[0]
                    : "Unknown"}
                </Button>
              </Trigger>
              <Menu placement="bottom-start" hasArrow>
                <Item value="sign-out" onClick={handleLogoutRedirect}>
                  Sign out
                </Item>
              </Menu>
            </Dropdown>
          </HeaderItem>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Button onClick={() => handleLoginRedirect(token)}>Sign In</Button>
        </UnauthenticatedTemplate>
      </Header>
    </>
  );
};
