import {fetchEndpoint, fetchEndpointRaw, HTTPMethods} from "../utils/FetchUtils";

//TODO: Move token retrieval
export async function resolveSubscripton(accessToken, subscriptionToken) {
  const header = {
    Authorization: "Bearer " + accessToken,
    "x-ms-marketplace-token": subscriptionToken,
  };
  const relativeUrl =
    process.env.REACT_APP_BACKEND_URL + "/api/subscription-resolve";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
  });
}

export async function activateSubscription(
  accessToken,
  subscriptionId,
  planId
) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({
    subscriptionId: subscriptionId,
    planId: planId,
    quantity: "",
  });
  const relativeUrl = process.env.REACT_APP_BACKEND_URL + "/api/activation";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
  });
}

export async function getAllSubscriptionsForUser(accessToken) {
  const header = { Authorization: "Bearer " + accessToken };
  const relativeUrl =
    process.env.REACT_APP_BACKEND_URL + "/api/get-all-subscriptions-for-user";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
  });
}

export async function getAllAssignedUsers(accessToken, subscriptionId) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({ data: { subscriptionId: subscriptionId } });
  const relativeUrl =
    process.env.REACT_APP_BACKEND_URL + "/api/get-all-assigned-users";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
  });
}

export async function addUserToSubscription(
  accessToken,
  users,
  subscriptionId
) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({
    data: { users: users, subscriptionId: subscriptionId },
  });
  const relativeUrl = process.env.REACT_APP_BACKEND_URL + "/api/assign-user";
  return await fetchEndpointRaw({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
  });
}

export async function removeUserFromSubscription(
  accessToken,
  users,
  subscriptionId
) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({
    data: { users: users, subscriptionId: subscriptionId },
  });
  const relativeUrl = process.env.REACT_APP_BACKEND_URL + "/api/remove-user";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
  });
}

export async function cancelSubscription(accessToken, subscriptionId) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({data: { subscriptionId: subscriptionId }});
  const relativeUrl =
    process.env.REACT_APP_BACKEND_URL + "/api/cancel-subscription";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
    timeoutMs: 240000
  });
}

export async function getSubscriptionSeats(accessToken, subscriptionId) {
  const header = { Authorization: "Bearer " + accessToken };
  const body = JSON.stringify({data: { subscriptionId: subscriptionId }});
  const relativeUrl =
      process.env.REACT_APP_BACKEND_URL + "/api/get-subscription-seats";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.POST,
    headerOptions: header,
    body: body,
    timeoutMs: 240000
  });
}


export async function getAdminConsent(tenant, client_id) {
  //const header = { 'Authorization': 'Bearer ' + accessToken};
  const relativeUrl =
    `https://login.microsoftonline.com/${tenant}/adminconsent?` +
    `client_id=${client_id}` +
    "&redirect_uri=http://localhost:3000/assignment";
  return await fetchEndpoint({
    relativeUrl: relativeUrl,
    method: HTTPMethods.GET,
    //headerOptions: header,
  });
}
