import React, {useCallback, useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {Field, Label, Checkbox} from "@zendeskgarden/react-forms";
import {
    Body,
    Cell,
    Head,
    HeaderCell,
    HeaderRow,
    Row,
    Table,
} from "@zendeskgarden/react-tables";
import {KEY_CODES} from "@zendeskgarden/container-utilities";
import {InteractionStatus} from "@azure/msal-browser";
import {Button, Anchor} from "@zendeskgarden/react-buttons";
import {Row as RowGrid, Col} from "@zendeskgarden/react-grid";
import UserAssignmentDrawer from "../components/userAssignmentDrawer";
import {Breadcrumb} from '@zendeskgarden/react-breadcrumbs';
import {callMSGraph} from "../utils/queryGraphApi";
import {useNavigate, useParams} from "react-router-dom";
import {
    addUserToSubscription,
    getAllAssignedUsers, getSubscriptionSeats,
    removeUserFromSubscription,
} from "../api/licenseManagement";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {Close, Footer, FooterItem, Header, Modal, Body as ModelBody} from "@zendeskgarden/react-modals";
import {Title, Close as ToastClose, useToast, Notification} from "@zendeskgarden/react-notifications";

const isSelectAllIndeterminate = (rows) => {
    const numSelectedRows = rows.reduce((accumulator, row) => {
        if (row.selected) {
            return accumulator + 1;
        }

        return accumulator;
    }, 0);

    return numSelectedRows > 0 && numSelectedRows < rows.length;
};

const isSelectAllChecked = (rows) => rows.every((row) => row.selected);

function LicenseAssignmentPage() {
    const {subscriptionId} = useParams();
    const {instance, accounts, inProgress} = useMsal();
    const navigate = useNavigate();
    const {addToast} = useToast();
    const tokenRequest = {
        scopes: process.env.REACT_APP_API_SCOPES.split(' '),
    };
    const [data, setData] = useState([]);
    const [optionData, setOptionData] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);
    const [visible, setVisible] = useState(false);
    const [shiftEnabled, setShiftEnabled] = useState(false);
    const [focusedRowIndex, setFocusedRowIndex] = useState(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [maxSeats, setMaxSeats] = useState(0);
    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);
    const navigateOverview = () => navigate(`/overview`);

    useEffect(() => {
        if (isOpen && inProgress === InteractionStatus.None && accounts.length > 0) {
            if (optionData.length > 0) {
                // Skip data refresh if already set - adjust logic for your specific use case
                return;
            }
            const tokenRequestGraph = {
                scopes: ["User.Read"],
                authority: `https://login.microsoftonline.com/common`,
            };
            // Acquire an access token
            instance.acquireTokenSilent(tokenRequestGraph).then((response) => {
                // Call your API with the access token and return the data you need to save in state
                // TODO: add more performant laoding of users
                callMSGraph("", response.accessToken).then((r) => {
                    setOptionData(r)
                });
            });
            instance.acquireTokenSilent(tokenRequest).then((response) => {

                getSubscriptionSeats(response.accessToken, subscriptionId).then((r) => {
                    setMaxSeats(r[0]?.number_of_seats);
                });
            })
        }

    }, [isOpen, inProgress, accounts, instance, optionData.length]);


    useEffect(() => {
        setLoadingContent(true);
        instance.acquireTokenSilent(tokenRequest).then((response) => {
            // Call your API with the access token and return the data you need to save in state
            getAllAssignedUsers(response.accessToken, subscriptionId).then((r) => {
                setData(r);
                setLoadingContent(false);
            });


        });
    }, [instance, subscriptionId]);


    function setUser(users) {
        instance.acquireTokenSilent(tokenRequest).then(async (response) => {
            // Call your API with the access token and return the data you need to save in state
            const addUserResponse = await addUserToSubscription(response.accessToken, users, subscriptionId)
            if (addUserResponse.status !== 200) {
                showToast("Error", "The user selected users could not be added to the subscription!")
            } else {
                let temp_data = []
                for (let i = 0; i < users.length; i++) {
                    if (!data.some((el) => el.email_id === users[i])) {
                        temp_data.push({status: "subscribed", email_id: users[i], name: users[i]});
                    }
                }
                setData([...data, ...temp_data]);
            }
        });

    }

    function removeUser() {
        const removeUser = data.filter((el) => el.selected === true);
        if (removeUser.length > 0) {
            const keepUser = data.filter((el) => !el.hasOwnProperty("selected"));
            instance.acquireTokenSilent(tokenRequest).then(async (response) => {
                // Call your API with the access token and return the data you need to save in state
                await removeUserFromSubscription(
                    response.accessToken,
                    removeUser,
                    subscriptionId
                )
                setData(keepUser);
            });
        }
        setVisible(false)
    }

    const showToast = useCallback(
        (title, message) => {
            return () => {
                addToast(
                    ({close}) => (
                        <Notification type="warning" style={{maxWidth: 450}}>
                            <Title>{title}</Title>
                            {message}
                            <ToastClose aria-label="Close" onClick={close}/>
                        </Notification>
                    ),
                    {placement: 'bottom-end'}
                );
            };
        },
        [addToast]
    );

    const filteredOptionsData = optionData.filter(item1 => !data.some(item2 => item1.email === item2.email_id || item1.userPrincipalName === item2.email_id))
    return (
        <div
            style={{minHeight: "400px", marginTop: "auto", marginBottom: "auto"}}
            className="flex flex-col"
        >
            <UserAssignmentDrawer
                close={close}
                isOpen={isOpen}
                optionData={filteredOptionsData}
                setConfirmedItems={setUser}
                maxSeats={maxSeats}
                assignedUsers={data}
            />
            <div className="mb-8">
                <Breadcrumb>
                    <Anchor onClick={navigateOverview}>Licenses</Anchor>
                    <span>{subscriptionId}</span>
                </Breadcrumb>
            </div>
            <RowGrid justifyContent="start" alignItems="start">
                <Col textAlign="left" style={{flexGrow: 0}}>
                    <Button isPrimary onClick={open}>
                        Assign User
                    </Button>
                </Col>
                <Col textAlign="left" style={{flexGrow: 0}}>
                    <Button onClick={() => setVisible(true)} disabled={!data.some((el) => el.selected === true)}>Remove
                        User</Button>
                </Col>
            </RowGrid>
            <div style={{overflowX: "auto"}} className="pt-4">
                <Table style={{minWidth: 450}}>
                    <Head>
                        <HeaderRow>
                            <HeaderCell isMinimum>
                                <Field>
                                    <Checkbox
                                        indeterminate={isSelectAllIndeterminate(data)}
                                        checked={isSelectAllChecked(data)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                const updatedRows = data.map((row) => ({
                                                    ...row,
                                                    selected: true,
                                                }));

                                                setData(updatedRows);
                                            } else {
                                                const updatedRows = data.map((row) => ({
                                                    ...row,
                                                    selected: false,
                                                }));

                                                setData(updatedRows);
                                            }
                                        }}
                                    >
                                        <Label hidden>Select all tickets</Label>
                                    </Checkbox>
                                </Field>
                            </HeaderCell>
                            <HeaderCell>Email</HeaderCell>
                        </HeaderRow>
                    </Head>
                    <Body>
                        {loadingContent ?
                            <Row>
                                <Cell colSpan={6}>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                </Cell>
                            </Row>
                            :
                            data.length > 0 ?
                                data.map((row, index) => (
                                    <Row key={row.id} isSelected={row.selected}>
                                        <Cell isMinimum>
                                            <Field>
                                                <Checkbox
                                                    checked={row.selected}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === KEY_CODES.SHIFT) {
                                                            setShiftEnabled(true);
                                                        }
                                                    }}
                                                    onKeyUp={() => {
                                                        setShiftEnabled(false);
                                                    }}
                                                    onChange={(e) => {
                                                        const updatedRows = [...data];

                                                        if (shiftEnabled && focusedRowIndex !== undefined) {
                                                            const startIndex = Math.min(focusedRowIndex, index);
                                                            const endIndex = Math.max(focusedRowIndex, index);

                                                            const isAllChecked = updatedRows
                                                                .slice(startIndex, endIndex + 1)
                                                                .every((slicedRow) => slicedRow.selected);

                                                            for (let x = startIndex; x <= endIndex; x++) {
                                                                if (x === index && isAllChecked) {
                                                                    updatedRows[x].selected = true;
                                                                    continue;
                                                                }

                                                                updatedRows[x].selected = !isAllChecked;
                                                            }
                                                        } else updatedRows[index].selected = e.target.checked;

                                                        setData(updatedRows);
                                                        setFocusedRowIndex(index);
                                                    }}
                                                >
                                                    <Label hidden>Select User</Label>
                                                </Checkbox>
                                            </Field>
                                        </Cell>
                                        <Cell>{row.email_id.toLowerCase()}</Cell>
                                    </Row>
                                ))
                                : (
                                    <Cell colSpan={5} className="h-32">
                                        <div className="flex justify-center">
                                            Click on "Assign User" to assign users to your subscription
                                        </div>
                                    </Cell>
                                )}
                    </Body>
                </Table>
            </div>
            {visible && (
                <Modal onClose={() => setVisible(false)}>
                    <Header tag="h2">Confirm User removal</Header>
                    <ModelBody>
                        <p>The following users will be removed from your subscription</p>
                        <br/>
                        <ul>
                            {
                                data.filter((el) => el.selected === true).map((item) => {
                                    return <li>{item.email_id}</li>
                                })

                            }
                        </ul>
                        <br/>
                        <p>The access to the Team Timekeeper for the selected users will be revoked immediately.</p>
                        <br/>
                        <p>Have questions? Contact us at <a
                            href="mailto:support@teams-timer.com"> support@teams-timer.com</a>.</p>
                    </ModelBody>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setVisible(false)} isBasic>
                                Return
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={() => removeUser()}>
                                Remove Users
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>)}
        </div>
    );
}

export default LicenseAssignmentPage;
