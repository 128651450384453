/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import React, {useState, useEffect} from "react";
import {Button} from "@zendeskgarden/react-buttons";
import {Col, Row} from "@zendeskgarden/react-grid";
import {DrawerModal} from "@zendeskgarden/react-modals";
//import debounce from "lodash.debounce";
import {
    Dropdown,
    Multiselect,
    Field,
    Menu,
    Item,
    Label,
} from "@zendeskgarden/react-dropdowns";
import {Tag} from "@zendeskgarden/react-tags";

const UserAssignmentDrawer = ({
                                  close,
                                  isOpen,
                                  optionData,
                                  setConfirmedItems,
                                  maxSeats,
                                  assignedUsers
                              }) => {
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [matchingOptions, setMatchingOptions] = useState(optionData);
    const [selectedItems, setSelectedItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setIsLoading(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email format validation regex

        const matchedOptions = optionData.filter(
            (option) =>
                ((option.mail &&
                        option.mail
                            .trim()
                            .toLowerCase()
                            .indexOf(inputValue.trim().toLowerCase()) !== -1) ||
                    (option.userPrincipalName &&
                        emailRegex.test(option.userPrincipalName) &&
                        option.userPrincipalName
                            .trim()
                            .toLowerCase()
                            .indexOf(inputValue.trim().toLowerCase()) !== -1))
        );
        setMatchingOptions(matchedOptions.filter(item => !assignedUsers.includes(item)));
        setIsLoading(false);
        //filterMatchingOptionsRef.current(inputValue);
    }, [inputValue, optionData]);

    const onStateChange = (value) => {
        setInputValue(value)
    };

    const onSelect = (value) => {
        if ((selectedItems.length + assignedUsers.length) === maxSeats) {
            setErrorMessage(`You can only assign up to ${maxSeats} User. You can increase the number of users by modifying your subscription`);
        } else {
            setSelectedItems(value)
            setErrorMessage('');
        }

    };

    const renderOptions = () => {
        if (isLoading) {
            return <Item disabled>Loading</Item>;
        }

        if (matchingOptions.length === 0) {
            return <Item disabled>No Users found</Item>;
        }

        return matchingOptions.map((option) => (
            <Item key={option.mail ? option.mail : option.userPrincipalName}
                  value={option.mail ? option.mail : option.userPrincipalName}>
                <span>{option.mail ? option.mail : option.userPrincipalName}</span>
            </Item>
        ));
    };

    const handleConfirm = () => {
        close();
        setConfirmedItems(selectedItems);
    };

    return (
        <Row>
            <Col textAlign="center">
                <DrawerModal isOpen={isOpen} onClose={close} className="w-100 md:w-32">
                    <DrawerModal.Header tag="h2">Assign users</DrawerModal.Header>
                    <DrawerModal.Body>
                        <div className="mt-8 mb-4">
                            <p>Select users that you want to assign to your subscriptions. Start typing to see
                                recommendations!</p>
                        </div>
                        <Row justifyContent="left">
                            <Col sm={12}>
                                {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
                                <Dropdown
                                    inputValue={inputValue}
                                    selectedItems={selectedItems}
                                    onSelect={(items) => onSelect(items)}
                                    downshiftProps={{defaultHighlightedIndex: 0}}
                                    onInputValueChange={onStateChange}
                                >
                                    <Field>
                                        <Label>Users</Label>
                                        <Multiselect
                                            renderItem={({value, removeValue}) => (
                                                <Tag>
                                                    <span>{value}</span>
                                                    <Tag.Close onClick={() => removeValue()}/>
                                                </Tag>
                                            )}
                                        />
                                    </Field>
                                    <Menu>{renderOptions()}</Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </DrawerModal.Body>
                    <DrawerModal.Footer>
                        <DrawerModal.FooterItem>
                            <Button isBasic onClick={close}>
                                Cancel
                            </Button>
                        </DrawerModal.FooterItem>
                        <DrawerModal.FooterItem>
                            <Button isPrimary onClick={handleConfirm}>
                                Confirm
                            </Button>
                        </DrawerModal.FooterItem>
                    </DrawerModal.Footer>
                    <DrawerModal.Close/>
                </DrawerModal>
            </Col>
        </Row>
    );
};

export default UserAssignmentDrawer;
