export const HTTPMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
};

export const HTTPRetryStatuses = [429, 556];

export const getUrl = (relativeUrl) => {
    if (
        process.env.SASS_MARKETPLACE_URL === "true" &&
        !relativeUrl.startsWith("/")
    ) {
        return "/" + relativeUrl;
    }
    return relativeUrl;
};

export const fetchEndpointRaw = ({
                                     relativeUrl,
                                     method = HTTPMethods.GET,
                                     body = undefined,
                                     headerOptions = {},
                                     options = {},
                                     timeoutMs = undefined,
                                 }) => {
    const url = getUrl(relativeUrl); // Use getUrl for URL formation
    const headers = {
        ...headerOptions,
    };

    const defaultOptions = {
        mode: "cors",
    };

    const abortController = new AbortController();
    if (timeoutMs) {
        setTimeout(() => abortController.abort(), timeoutMs);
    }

    return fetch(url, {
        method,
        headers,
        ...(body && { body }),
        ...defaultOptions,
        ...options,
        ...(timeoutMs && { signal: abortController.signal }),
    });
};

export const fetchEndpoint = ({
                                  relativeUrl,
                                  method = HTTPMethods.GET,
                                  body = undefined,
                                  headerOptions = {},
                                  options = {},
                                  timeoutMs = undefined,
                                  retries = 7,
                                  initialDelay = 1000,
                                  success = defaultResponseParser,
                                  error = defaultError,
                              }) => {
    return new Promise((resolve, reject) =>
        retry(
            () =>
                fetchEndpointRaw({
                    relativeUrl,
                    method,
                    body,
                    headerOptions,
                    options,
                    timeoutMs,
                }),
            {
                retries,
                interval: initialDelay,
                retryIntervalMultiplier: 2,
                successCondition: (res) => res && res.ok,
                success: ({ res }) => success({ resolve, reject, response: res }),
                errorCondition: (res) =>
                    !res || (!res.ok && !HTTPRetryStatuses.includes(res.status)),
                error: ({ res, err }) =>
                    error({ resolve, reject, response: res, err: err }),
            }
        )
    );
};

export const retry = async (
    fn,
    {
        retries = 2,
        interval = 500,
        retryIntervalMultiplier = 1,
        successCondition = () => true,
        success = ({ res }) => res,
        errorCondition = () => false,
        error = ({ res, err }) => {
            throw err || new Error("An error occurred during the request.");
        },
    } = {}
) => {
    try {
        const fnResult = await fn();
        if (successCondition(fnResult)) return success({ res: fnResult });
        if (retries === 0 || errorCondition(fnResult))
            return error({ res: fnResult });
        await new Promise((resolve) => setTimeout(resolve, interval));
        return retry(fn, {
            retries: retries - 1,
            interval: interval * retryIntervalMultiplier,
            retryIntervalMultiplier,
            success,
            error,
            successCondition,
            errorCondition,
        });
    } catch (err) {
        return error({ err });
    }
};

export const parseResponse = ({ resolve, response, parser }) => {
    response.text().then((text) => {
        try {
            resolve(parser(text));
        } catch {
            resolve(text);
        }
    });
};

export const defaultResponseParser = ({ resolve, response }) =>
    parseResponse({ resolve, response, parser: JSON.parse });

export const defaultError = ({ resolve, reject, response, err }) => {
    if (response) {
        response.text().then((text) => console.error("Fetch failed: ", text));
    } else if (err) {
        console.error("Fetch failed: ", err);
    }
    reject(err || new Error("Fetch failed"));
};
