import React, {useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {Checkbox, Field, Label} from "@zendeskgarden/react-forms";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table,} from "@zendeskgarden/react-tables";
import {Body as ModelBody, Close, Footer, FooterItem, Header, Modal} from '@zendeskgarden/react-modals';
import {KEY_CODES} from "@zendeskgarden/container-utilities";
import {cancelSubscription, getAllSubscriptionsForUser,} from "../api/licenseManagement";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {Button} from "@zendeskgarden/react-buttons";
import {Col, Grid, Row as RowGrid} from "@zendeskgarden/react-grid";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {useNavigate} from "react-router-dom";
import {Title, Well} from '@zendeskgarden/react-notifications';
import {loginRequest} from "../authConfig";
import {Tag} from "@zendeskgarden/react-tags";

const isSelectAllIndeterminate = (rows) => {
    const numSelectedRows = rows.reduce((accumulator, row) => {
        if (row.selected) {
            return accumulator + 1;
        }

        return accumulator;
    }, 0);

    return numSelectedRows > 0 && numSelectedRows < rows.length;
};

const isSelectAllChecked = (rows) => rows.every((row) => row.selected);

const NoSubscriptionNotification = () => {
    return (
        <Well style={{padding: "16px", marginTop: "36px"}}>
            <Grid>
                <RowGrid>
                    <Col textAlign={"center"}>
                        <Title className="pb-8">No Subscription</Title>
                        <div className="pb-8">
                            <p>You currently do not have an active subscription. Please subscribe to access the meeting
                                timer.</p>
                        </div>
                        <SubscribeButton/>
                    </Col>
                </RowGrid>
            </Grid>

        </Well>
    );
};

const SubscribeButton = () => {
    return (
        <Button className="pt-8" isPrimary
                onClick={() => window.open(
                    "https://portal.azure.com/#view/Microsoft_Azure_Marketplace/MarketplaceOffersBlade/selectedMenuItemId/home", '_blank'
                )}>Buy
        </Button>
    )
}


function LicenseOverviewPage() {
    const navigate = useNavigate();

    const {instance, accounts, inProgress} = useMsal();
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const [shiftEnabled, setShiftEnabled] = useState(false);
    const [focusedRowIndex, setFocusedRowIndex] = useState(undefined);
    const [singleSubSelected, setSingleSubSelected] = useState(false);

    const getSelectedSubscription = () => {
        return data.filter((e) => e.selected === true)[0][
            "subscription_id"
            ]
    }

    const getSelectedSubscriptionName = () => {
        return data.filter((e) => e.selected === true)[0][
            "subscription_name"
            ]
    }


    const assignLicenses = () => {
        if (singleSubSelected) {
            const id = data.filter((e) => e.selected === true)[0]["subscription_id"];
            /* vendors contains the element we're looking for */
            navigate(`/assignment/${id}`);
        }
    };

    const cancelSub = () => {
        if (singleSubSelected) {
            const tokenRequest = {
                scopes: process.env.REACT_APP_API_SCOPES.split(' '),
            };
            const subscription_id = getSelectedSubscription()
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                // Call your API with the access token and return the data you need to save in state

                cancelSubscription(response.accessToken, subscription_id).then(
                    (responseData) => {
                        setData(responseData);
                        setLoading(false);
                    }
                );
            });
            setVisible(false)
        }
    };

    const checkLicenseAssignable = React.useCallback(() => {
        if (data.filter((e) => e.selected === true).length === 1) {
            setSingleSubSelected(true);
        } else {
            setSingleSubSelected(false);
        }
    }, [data]);

    useEffect(() => {
        if (
            !loading &&
            inProgress === InteractionStatus.None &&
            accounts.length > 0
        ) {
            if (data.length > 0) {
                // Skip data refresh if already set - adjust logic for your specific use case
                return;
            }
            const tokenRequest = {
                scopes: process.env.REACT_APP_API_SCOPES.split(' '),
            };
            setLoadingContent(true);
            // Acquire an access token
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                // Call your API with the access token and return the data you need to save in state

                getAllSubscriptionsForUser(response.accessToken).then(
                    (responseData) => {
                        setData(responseData);
                        setLoading(false);
                        setLoadingContent(false);
                    }
                );
            }).catch((error) => {
                // Check if the error is InteractionRequiredAuthError
                if (error instanceof InteractionRequiredAuthError) {
                    // Handle the error
                    // For example, redirect the user to a login page or show a message
                    instance.loginRedirect({
                        ...loginRequest,
                    })
                } else {
                    // Handle any other errors
                    console.error('An unexpected error occurred:', error);
                }
            });
        }
    }, [inProgress, accounts, instance, loading, data.length]);

    useEffect(() => {
        checkLicenseAssignable();
    }, [data, checkLicenseAssignable]);

    function getSubscriptionStatusIndicator(status) {
        if (status === "Subscribed") {
            return (
                <Tag hue="green">
                    <span>Subscribed</span>
                </Tag>
            )
        } else {
            return (
                <Tag isRegular>
                    <span>Not Subscribed</span>
                </Tag>
            )
        }
    }

    function formatSubscriptionDate(dateString) {
        const inputDate = new Date(dateString);
        const today = new Date();

        // Reset time for 'today' to ensure a fair date comparison
        today.setHours(0, 0, 0, 0);

        if (inputDate < today) {
            return "-";
        }

        const day = String(inputDate.getDate()).padStart(2, "0");
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const year = inputDate.getFullYear();

        return `${day}-${month}-${year}`;
    }

    function formatSeatseNumber(number) {
        return number > 9999 ? "∞" : number;
    }

    return (
        <div
            style={{minHeight: "400px", marginTop: "auto", marginBottom: "auto"}}
            className="flex flex-col"
        >
            {visible && (
                <Modal onClose={() => setVisible(false)}>
                    <Header tag="h2">Cancel Subscription</Header>
                    <ModelBody>
                        <p className="text-lg">Confirm your cancellation</p>
                        <br/>
                        <p>You are about to cancel your subscription to the Meeting Timer.</p>
                        <br/>
                        <p className="font-bold">Subscription Name: {getSelectedSubscriptionName()}</p>
                        <br/>
                        <p>Your access will be revoked immediately. If you ever decide to return, we'll welcome you
                            back.</p>
                        <p>Have questions? Contact us at <a
                            href="mailto:support@teams-timer.com"> support@teams-timer.com</a>.</p>
                        <br/>
                        <p>Thank you for using the Meeting Timer!</p>
                    </ModelBody>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setVisible(false)} isBasic>
                                Return
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={() => cancelSub()}>
                                Cancel Subscription
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>)}
            <RowGrid justifyContent="start" alignItems="start">
                <Col textAlign="left" style={{flexGrow: 0}}>
                    <SubscribeButton/>
                </Col>
                <Col textAlign="left" style={{flexGrow: 0}}>
                    <Button
                        onClick={() => assignLicenses()}
                        disabled={!singleSubSelected}
                    >
                        Assign
                    </Button>
                </Col>
                <Col textAlign="left">
                    <Button
                        disabled={!singleSubSelected}
                        onClick={() => setVisible(true)}
                    >
                        Cancel Subscription
                    </Button>
                </Col>
            </RowGrid>
            <div style={{overflowX: "auto"}} className="pt-4">
                <Table style={{minWidth: 450}}>
                    <Head>
                        <HeaderRow>
                            <HeaderCell isMinimum>
                                <Field>
                                    <Checkbox
                                        indeterminate={isSelectAllIndeterminate(data)}
                                        checked={isSelectAllChecked(data)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                const updatedRows = data.map((row) => ({
                                                    ...row,
                                                    selected: true,
                                                }));

                                                setData(updatedRows);
                                            } else {
                                                const updatedRows = data.map((row) => ({
                                                    ...row,
                                                    selected: false,
                                                }));

                                                setData(updatedRows);
                                            }
                                        }}
                                    >
                                        <Label hidden>Select all tickets</Label>
                                    </Checkbox>
                                </Field>
                            </HeaderCell>
                            <HeaderCell>ID</HeaderCell>
                            <HeaderCell>Name</HeaderCell>
                            <HeaderCell>Status</HeaderCell>
                            <HeaderCell>Plan</HeaderCell>
                            <HeaderCell>End Date</HeaderCell>
                            <HeaderCell>Assigned Seats</HeaderCell>
                        </HeaderRow>
                    </Head>
                    <Body>
                        {loadingContent ? (
                                <Row>
                                    <Cell colSpan={7}>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </Cell>
                                </Row>
                            ) :
                            data.map((row, index) => (
                                <Row key={row.id} isSelected={row.selected}>
                                    <Cell isMinimum>
                                        <Field>
                                            <Checkbox
                                                checked={row.selected}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === KEY_CODES.SHIFT) {
                                                        setShiftEnabled(true);
                                                    }
                                                }}
                                                onKeyUp={() => {
                                                    setShiftEnabled(false);
                                                }}
                                                onChange={(e) => {
                                                    const updatedRows = [...data];

                                                    if (shiftEnabled && focusedRowIndex !== undefined) {
                                                        const startIndex = Math.min(focusedRowIndex, index);
                                                        const endIndex = Math.max(focusedRowIndex, index);

                                                        const isAllChecked = updatedRows
                                                            .slice(startIndex, endIndex + 1)
                                                            .every((slicedRow) => slicedRow.selected);

                                                        for (let x = startIndex; x <= endIndex; x++) {
                                                            if (x === index && isAllChecked) {
                                                                updatedRows[x].selected = true;
                                                                continue;
                                                            }

                                                            updatedRows[x].selected = !isAllChecked;
                                                        }
                                                    } else updatedRows[index].selected = e.target.checked;

                                                    setData(updatedRows);
                                                    setFocusedRowIndex(index);
                                                }}
                                            >
                                                <Label hidden>Select for {row.plan_id}</Label>
                                            </Checkbox>
                                        </Field>
                                    </Cell>
                                    <Cell>{row.subscription_id}</Cell>
                                    <Cell>{row.subscription_name}</Cell>
                                    <Cell>
                                        {getSubscriptionStatusIndicator(row.saasSubscriptionStatus)}
                                    </Cell>
                                    <Cell>{row.plan_id}</Cell>
                                    <Cell>
                                        {formatSubscriptionDate(row.subscription_end_date)}
                                    </Cell>
                                    <Cell>{formatSeatseNumber(row.number_of_seats)}</Cell>
                                </Row>
                            ))
                        }
                    </Body>
                </Table>
                <>
                    {!loadingContent && data.length === 0 ?
                        <Grid>
                            <RowGrid>
                                <Col>
                                    <NoSubscriptionNotification/>
                                </Col>
                            </RowGrid>
                        </Grid>
                        : <></>
                    }
                </>
            </div>
        </div>
    );
}

export default LicenseOverviewPage;
