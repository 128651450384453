import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { NavigationBar } from "./NavigationBar.js";
import {Title, Well} from "@zendeskgarden/react-notifications";
import {Col, Grid, Row as RowGrid} from "@zendeskgarden/react-grid";
import React from "react";

const Footer = () => {
  return(
      <footer className="mt-auto pb-4">
        <center>
          <div className="w-5/6 mx-auto" style={{borderTop: "1px solid", borderColor: "#d8dcde"}}>
            <ul className="flex justify-center space-x-4 py-3">
              <li><a href="https://teams-timer.com/agb" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
              <li><a href="https://teams-timer.com/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              <li><a href="https://teams-timer.com/support-help-faq" target="_blank" rel="noopener noreferrer">Support / Help / FAQs</a></li>
            </ul>
          </div>
        </center>
      </footer>
  )
}

export const PageLayout = (props) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <div>
      <body
        style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
      >
        <NavigationBar />
        <br />
        <UnauthenticatedTemplate>
          <div className="mx-2 sm:mx-16 grow mt-16 mb-auto">
            <Well style={{padding: "16px", marginTop: "36px"}}>
              <Grid>
                <RowGrid>
                  <Col textAlign={"center"}>
                    <Title className="pb-8">Welcome to the Team Timekeeper Admin Page</Title>
                    <div className="pb-8">
                      <p>You are currently not signed. Please sign-in to view and manage your subscription</p>
                    </div>
                  </Col>
                </RowGrid>
              </Grid>

            </Well>
          </div>
          <Footer/>
        </UnauthenticatedTemplate>

        <AuthenticatedTemplate>
          <br />
          <div className="mx-2 sm:mx-16 grow mt-16 mb-auto">{props.children}</div>
          <Footer/>
        </AuthenticatedTemplate>
      </body>
    </div>
  );
};
