import { fetchEndpoint, HTTPMethods } from "./FetchUtils.js";

export function callMSGraph(endpoint, token, callback) {
  const header = { Authorization: `Bearer ${token}` };

  console.log("request made to Graph API at: " + new Date().toString());
  return fetchEndpoint({
    relativeUrl: "https://graph.microsoft.com/v1.0/users",
    method: HTTPMethods.GET,
    headerOptions: header,
  })
    .then((response) => response.value)
    .catch((error) => console.log(error));
}
